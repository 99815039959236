import Vue from 'vue'
import Router from "vue-router";
import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
Vue.use(VueMeta)



const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
    
      {
        path: '/auth',
        name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      
      {
        path: '/',
        name:"admin",
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
           if (localStorage.getItem('tokinn') != null & store.state.AdminInfo.authe==true) {
          
            next();
          } else {
            next({
              name: 'Login'
            });
          }
        },   
        children: [
          // {
          //   name: 'statistics',
          //   path: 'statistics',
          //   component: () => import('./views/dashboard/Dashboard.vue')
          // },

            {
            name: 'articles',
            path: 'articles',
            component: () => import('./views/dashboard/articles.vue')
          },


          {
            path: 'about',
            name:'about',
            component: () => import('./views/dashboard/about.vue'),
            
          },

          {
            path: 'precious',
            name:'precious',
            component: () => import('./views/dashboard/precious.vue'),
            
          },
          
          {
            path: 'Gallery',
            name:'Gallery',
            component: () => import('./views/dashboard/Gallery.vue'),
            
          },

          {
            path: 'GalleryCats',
            name:'GalleryCats',
            component: () => import('./views/dashboard/GalleryCats.vue'),
            
          },
          //GalleryCats.vue

          {
            path: 'articles',
            name:'articles',
            component: () => import('./views/dashboard/articles.vue'),
            
          },
          {
            path: 'messages',
            name:'messages',
            component: () => import('./views/dashboard/messages.vue'),
            
          },

          {
            path: 'artCats',
            name:'artCats',
            component: () => import('./views/dashboard/artCats.vue'),
            
          },

          {
            path: 'sections',
            name:'sections',
            component: () => import('./views/dashboard/sections.vue'),

          },

          {
            path: 'structure',
            name:'structure',
            component: () => import('./views/dashboard/structure.vue'),

          },
          {
            path:'corridors',
            name:'corridors',
            component: () => import('./views/dashboard/corridors.vue'),
          },
          {
            path:'subjects',
            name:'subjects',
            component: () => import('./views/dashboard/subjects.vue'),
          },
          {
            path:'inputsSubjects',
            name:'inputsSubjects',
            component: () => import('./views/dashboard/inputsSubjects.vue'),
          },
          {
            path:'kafeelGift',
            name:'kafeelGift',
            component: () => import('./views/dashboard/kafeelGift.vue'),
          },
          {
            path:'callUs',
            name:'callUs',
            component: () => import('./views/dashboard/callUs.vue'),
          }


       
        ]   
        
      },

    
   
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

